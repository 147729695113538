import React from 'react'

import Layout from '../components/layout'
import ContactForm from '../components/contactForm'
import Quote from '../components/quote'
import SEO from '../components/seo'

const Team= () =>(
    <Layout>
        <SEO title="Our Team" keywords={[`gatsby5`, `application`, `react`]} />
        <h1>Our Team</h1>
        <ContactForm/>
        <Quote/>
    </Layout>
);

export default Team